"use strict";
/*
  Autogenerated, do not edit.
  All changes will be undone.
  types version: 0.0.0-snapshot-8f3e87409dbf1f30f602f865e4204ed97d15d5d6-2024-10-10
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.FleetAccountOptions = void 0;
class FleetAccountOptions {
    constructor(values) {
        Object.assign(this, values);
    }
}
exports.FleetAccountOptions = FleetAccountOptions;
